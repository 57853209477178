@font-face {
    font-family: "Caveat";
    src: url('./fonts/Caveat-VariableFont_wght.ttf') format('truetype');
  }
  @font-face {
    font-family: "Ivar Regular Display";
    src: url('./fonts/IvarDisplay-Regular.otf') format('opentype');
  }
  @font-face {
    font-family: "Ivar Fine Light";
    src: url('./fonts/IvarFine-Light.otf') format('opentype');
  }
  @font-face {
    font-family: 'Moderat';
    src: url('./fonts/Moderat-Regular.ttf') format('truetype');
  }
  @font-face {
    font-family: 'Moderat Medium';
    src: url('./fonts/Moderat-Medium.ttf') format('truetype');
  }
  @font-face {
    font-family: 'Moderat Bold';
    src: url('./fonts/Moderat-Bold.ttf') format('truetype');
  }
  html {
    font-size: 62.5%;
    font-family: 'Moderat';
     tab-size: 4;
  }
  body {
     margin: 0;
    }
  *, ::before, ::after {
    box-sizing: border-box;
  }
  nav ol, nav ul {
    list-style: none;
   padding: 0;
  }
  audio, canvas, iframe, img, svg, video {
    vertical-align: middle;
  }
  table {
    border-collapse: collapse; 
  }
  textarea {
    resize: vertical;
  }
  iframe,
  img,
  input,
  select,
  textarea {
    height: auto;
    max-width: 100%;
  }
  [aria-busy="true"] {
   cursor: progress;
  }
  [aria-controls] {
    cursor: pointer;
  }
  [aria-disabled="true"], [disabled] {
    cursor: default;
  }
  [aria-hidden="false"][hidden] {
    display: initial;
  }